.otp {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: auto;
  padding: 3rem 0;
  .wrap-otp {
    width: 100%;
    max-width: 50rem;
    margin: auto;
    @media screen and (max-width: 540px) {
      max-width: 100%;
    }
  }
  .title {
    position: relative;
    z-index: 2;
  }
  .logo {
    width: 20%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .banner {
    object-fit: contain;
    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  .title-checkin {
    font-size: 2.2rem;
    font-family: "SVN-gotham";
    color: #fff;
    text-wrap: balance;
    text-align: center;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 540px) {
      font-size: 1.8rem;
    }
  }
  .hashtag {
    width: 87%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 3rem;
    height: 5rem;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 540px) {
      margin: 1rem auto 2rem;
      height: 4rem;
    }
    .name {
      font-family: "SVN-gotham";
      background-color: #fff;
      color: #062f6e;
      display: flex;
      align-items: center;
      padding: 0 1rem;
      height: 100%;
      width: 70%;
      font-size: 1.6rem;
      margin-bottom: 0;

      @media screen and (max-width: 540px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 390px) {
        font-size: 1.2rem;
      }
    }
    .btn_cus {
      border-radius: 0;
      border: none;
      box-shadow: none;
      color: #fff;
      width: 30%;
      height: 100%;
      font-size: 1.6rem;
      margin-top: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;

      @media screen and (max-width: 540px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 390px) {
        font-size: 1.2rem;
      }
      &:hover {
        color: #fff;
        opacity: 0.8;
      }
    }
  }
  .img-main {
    position: relative;
    z-index: 1;
    padding: 3rem;
    .avatar {
      box-shadow: 7px 7px 10px #222;
      aspect-ratio: 1572/1777;
      object-fit: cover;
      width: 90%;
      margin: 0 auto;
    }
    .bg-img {
      position: absolute;
      top: 55%;
      right: 50%;
      transform: translate(50%, -50%);
      object-fit: contain;
      pointer-events: none;
      z-index: -1;
      width: 250%;
      max-width: unset;
      @media screen and (max-width: 540px) {
        width: 120%;
      }
    }
  }
  .btn-checkIn {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    width: 100%;
    .not-found {
      font-size: 2rem;
      font-weight: 400;
      font-family: "gotham";
      color: #fff;
      margin: auto;
      @media screen and (max-width: 540px) {
        font-size: 1.4rem;
      }
    }
    .ant-btn {
      font-size: 2rem;
      font-weight: 400;
      font-style: italic;
      font-family: "SVN-gotham";
      color: #fff;
      height: auto;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      border-radius: 3rem;
      border: 1px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 47%;
      @media screen and (max-width: 540px) {
        font-size: 1.4rem;
        padding: 0.8rem 1.2rem;
      }
      @media screen and (max-width: 320px) {
        font-size: 1.2rem;
      }
      &:hover {
        background-color: #fff;
        color: #ea2826;
        border-color: #fff;
      }
    }
    @media screen and (max-width: 540px) {
      padding: 0 3rem;
    }
    @media screen and (max-width: 320px) {
      padding: 0 1.6rem;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    span.ant-checkbox-inner {
      width: 2rem;
      height: 2rem;
      border-radius: 0;
    }
    .ant-form {
      margin-top: 2rem;
      padding: 0 4rem;
      @media screen and (max-width: 540px) {
        padding: 0 2.5rem;
      }
    }
    .ant-form-item {
      margin-bottom: 1rem;
      .ant-form-item-explain-error {
        color: #fff;
        font-size: 1.6rem;
        padding-left: 1rem;
      }
    }
    .ant-input {
      font-size: 2rem;
      font-weight: 400;
      font-family: "SVN-gotham";
      padding: 1rem 2.5rem;
      border-radius: 3rem;
      &::placeholder {
        color: #808080;
      }
      @media screen and (max-width: 540px) {
        font-size: 1.4rem;
      }
    }

    .checkbox {
      display: flex;
      padding: 2rem 0.8rem;
      .ant-form-item {
        margin-bottom: 0;
      }
      .text {
        font-size: 1.6rem;
        font-weight: 400;
        font-family: "gotham";
        padding-left: 1rem;
        color: #fff;
        display: flex;
        align-items: center;
        @media screen and (max-width: 540px) {
          font-size: 1.2rem;
          padding-left: 0.8rem;
        }
      }
      .ant-checkbox .ant-checkbox-inner {
        width: 3rem;
        height: 3rem;
        @media screen and (max-width: 540px) {
          width: 2rem;
          height: 2rem;
        }
      }
      .ant-checkbox .ant-checkbox-inner:after {
        width: 1rem;
        height: 2rem;
        @media screen and (max-width: 540px) {
          width: 0.6rem;
          height: 1.2rem;
        }
      }
    }
  }
}
