.share {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  .wrap {
    width: 100%;
    max-width: 50rem;
    margin: auto;
    padding: 3rem 0;
    @media screen and (max-width: 540px) {
      max-width: 100%;
    }
  }
  .logo {
    width: 20%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .banner {
    height: 30%;
    object-fit: contain;
  }
  .title-share {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  .img-main {
    position: relative;
    z-index: 1;
    padding: 3rem;
    .avatar {
      box-shadow: 7px 7px 10px #222;
      aspect-ratio: 1572/1777;
      object-fit: cover;
      width: 100%;
    }
    .bg-img {
      position: absolute;
      top: 55%;
      right: 50%;
      transform: translate(50%, -50%);
      object-fit: contain;
      pointer-events: none;
      z-index: -1;
      width: 250%;
      max-width: unset;
      @media screen and (max-width: 540px) {
        width: 120%;
      }
    }
  }
  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    z-index: 2;
    position: relative;
    @media screen and (max-width: 540px) {
      margin: 1rem 0;
    }

    span {
      font-size: 2rem;
      font-family: "SVN-gotham";
      color: #fff;
      @media screen and (max-width: 540px) {
        font-size: 1.6rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1.5rem;
      }
    }
  }
  .down {
    width: 2rem;
    height: 1.5rem;
    margin: 3rem auto;
  }
  .img-share {
    width: 100%;
    object-fit: cover;
    margin-top: 4rem;
  }
  .footer {
    background-color: #8e0e18;
    padding: 2rem 0;
    width: 100%;
    .text-img {
      width: 30%;
      margin: 2rem auto;
      @media screen and (max-width: 1024px) {
        width: 40%;
      }
      @media screen and (max-width: 820px) {
        width: 50%;
      }
      @media screen and (max-width: 540px) {
        width: 80%;
      }
    }
    .text-social {
      color: #fff;
      font-size: 2.2rem;
      font-family: "SVN-gotham";
      position: relative;
      text-align: center;
      font-style: italic;
      width: 30%;
      margin: 2rem auto;
      @media screen and (max-width: 1024px) {
        width: 40%;
      }
      @media screen and (max-width: 820px) {
        width: 50%;
        font-size: 1.8rem;
      }
      @media screen and (max-width: 540px) {
        width: 80%;
        font-size: 1.6rem;
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        height: 1px;
        width: 30%;
        background-color: #fff;
        transform: translateY(-50%);
        @media screen and (max-width: 1024px) {
          width: 22%;
        }
        @media screen and (max-width: 820px) {
          width: 20%;
        }
        @media screen and (max-width: 540px) {
          width: 22%;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 30%;
        height: 1px;
        background-color: #fff;
        transform: translateY(-50%);
        @media screen and (max-width: 1024px) {
          width: 22%;
        }
        @media screen and (max-width: 820px) {
          width: 20%;
        }
        @media screen and (max-width: 540px) {
          width: 22%;
        }
      }
    }
    .unilever {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 3rem;
      span {
        text-align: center;
        color: #fff;
        font-weight: 400;
        font-style: italic;
        font-size: 1.8rem;
        @media screen and (max-width: 820px) {
          font-size: 1.6rem;
        }
        @media screen and (max-width: 540px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}
