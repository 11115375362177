@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "gotham";
  font-weight: 900;
  font-style: italic;
  src: local("gotham"),
    url(../assets/font/gotham-black-italic.OTF) format("opentype");
}

@font-face {
  font-family: "gotham";
  font-weight: 700;
  font-style: italic;
  src: local("gotham"),
    url(../assets/font/gotham-bold-italic.OTF) format("opentype");
}

@font-face {
  font-family: "gotham";
  font-weight: 400;
  src: local("gotham"), url(../assets/font/gotham-medium.OTF) format("opentype");
}

@font-face {
  font-family: "SVN-gotham";
  font-weight: 900;
  font-style: italic;
  src: local("SVN-gotham"),
    url(../assets/font//SVN-Gotham-black-italic.ttf) format("truetype");
}

@font-face {
  font-family: "SVN-gotham";
  font-weight: 700;
  font-style: italic;
  src: local("SVN-gotham"),
    url(../assets/font/SVN-Gotham-bold-italic.otf) format("opentype");
}
body {
  overflow: hidden;
}
#root {
  overflow: hidden;
}

.App {
  background-color: #ec1d23;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: flex;
  font-style: italic;
  .wrapper {
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @media screen and (max-width: 300px) {
      padding: 2rem 1rem;
    }
  }
}
html {
  font-size: 10px;
}
.check_color_text {
  color: white;
  font-weight: bold;
  font-family: "SVN-gotham";
  font-style: italic;
}
.grecaptcha-badge {
  display: none !important;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.btn_cus {
  width: 100%;
  font-size: 2rem;
  font-weight: 400;
  font-family: "SVN-gotham";
  padding: 1rem 2.5rem;
  text-align: center;
  border-radius: 3rem;
  height: auto;
  background: #062f6e;
  color: #fff;
  margin-top: 2rem;
  cursor: pointer;
  &:hover {
    background: #062f6e;
    color: #7d809d;
  }
  &:disabled {
    opacity: 0.8;
    border: none;
  }
  @media screen and (max-width: 540px) {
    font-size: 1.6rem;
  }
}
